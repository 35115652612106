import axios from "axios";

const sendCareersFormEmail = (params) => {
  const { businessType, businessId, locationId } = params;
  const url = "https://api.gofisherman.com/";
  const fullUrl = `${url}businesses/${businessType}s/${businessId}/locations/2366/email/`;
  const message = `
    Preferred Location: ${params.preferredLocation}
    Job Type: ${params.jobType}
    Name: ${params.name}
    Home Address: ${params.homeAddress}
    Email: ${params.email}
    Phone Number: ${params.phoneNumber}
    Date Available To Start: ${params.dateAvailableToStart}
    Days Available: ${params.daysAvailable}
    Are you at least 18 years of age?: ${params.major}
    Are you legally authorized to work in the United States?: ${
      params.authorizedToWork
    }
    Highest Level of Education: ${params.educationLevel}

    Current Employer:
      Company Name: ${params.currentEmployerCompanyName}
      Address of Company: ${params.currentEmployerCompanyAddress} 
      Job Title: ${params.currentEmployerJobTitle} 
      Start Date: ${params.currentEmployerStartDate} 
      End Date: ${params.currentEmployerEndDate} 
      Responsibilities: ${params.currentEmployerResponsibilities}
      Reason for Leaving: ${params.currentEmployerReasonforLeaving}

    Past Employer:
      Company Name: ${params.pastEmployerCompanyName}
      Address of Company: ${params.pastEmployerCompanyAddress} 
      Job Title: ${params.pastEmployerJobTitle} 
      Start Date: ${params.pastEmployerStartDate} 
      End Date: ${params.pastEmployerEndDate} 
      Responsibilities: ${params.pastEmployerResponsibilities}
      Reason for Leaving: ${params.pastEmployerReasonforLeaving}

    Reference 1:
      Full Name: ${params.firstReferenceFullName}
      Phone Number: ${params.firstReferencePhoneNumber}
      Company: ${params.firstReferenceCompany}

    Reference 2: 
      Full Name: ${params.secondReferenceFullName}
      Phone Number: ${params.secondReferencePhoneNumber}
      Company: ${params.secondReferenceCompany}

    Any reason why you cannot perform the essential functions of this job?: ${
      params.disclaimer
    }

    Do you agree to the disclaimer above?: ${
      params.contact_opt_in ? "Yes" : "No"
    }
  `;

  return axios.post(fullUrl, {
    contact_name: params.name,
    contact_email: params.email,
    contact_message: message,
    contact_opt_in: params.contactOptIn,
    destination_email: "careers@eggnbird.com",
    email_subject:
      "Someone has reached out to you via your website career form",
  });
};

export default sendCareersFormEmail;
