import React from "react";
import { graphql } from "gatsby";
import { Grid, List } from "semantic-ui-react";
import Helmet from "react-helmet";
import { PageLayout, HeroElement, About } from "@fishrmn/fishrmn-components";
import axios from "axios";

import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import sendCareersFormEmail from "../utils/careersEmail";
import { CAREER_FORM_FIELDS } from "../utils/consts";

export default class extends React.PureComponent {
  renderJobDescription() {
    return (
      <div className="job-description-container">
        <h2>Job Description</h2>
        <List>
          <List.Item
            as="a"
            href="https://fisherman.gumlet.io/public/eggandbird/EnB_Store_Manager_v.2021.0621.pdf"
            rel="noreferrer"
            target="_blank"
            className="job-decription-item"
          >
            Store Manager
          </List.Item>
          <List.Item
            as="a"
            href="https://fisherman.gumlet.io/public/eggandbird/EnB_Assistant_Store_Manager_v.2021.0621.pdf"
            rel="noreferrer"
            target="_blank"
          >
            Assistant Store Manager
          </List.Item>
          <List.Item
            as="a"
            href="https://fisherman.gumlet.io/public/eggandbird/EnB_Line_Cook__v.2021.0721.pdf"
            rel="noreferrer"
            target="_blank"
          >
            Line Cook
          </List.Item>
          <List.Item
            as="a"
            href="https://fisherman.gumlet.io/public/eggandbird/EnB_Shift_Lead_v.2021.0621.docx.pdf"
            rel="noreferrer"
            target="_blank"
          >
            Shift Lead
          </List.Item>
          <List.Item
            as="a"
            href="https://fisherman.gumlet.io/public/eggandbird/EnB_Store_Associate_v.2021.0621.pdf"
            rel="noreferrer"
            target="_blank"
          >
            Store Associate
          </List.Item>
        </List>
      </div>
    );
  }

  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Careers</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Careers"}
              tagline={""}
              images={[data.heroImages.map(({ url }) => url)[4]]}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={true}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
          subfooter={false}
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={
                  <>
                    Thank you for your interest in becoming part of the Egg N
                    Bird family! Please make sure to complete the entire
                    application to be considered for a role. If any of the
                    following areas requested do not apply, you can reply "N/A".
                    Please send any additional materials to&nbsp;
                    <a href="mailto:careers@eggnbird.com">
                      careers@eggnbird.com
                    </a>
                    .
                  </>
                }
                header={""}
                centerContent={true}
                headerAs={null}
                withContainer={true}
              />
            </Grid.Column>
            <Grid.Column width={16}>{this.renderJobDescription()}</Grid.Column>
          </Grid>

          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12}>
              <h1>Apply Now</h1>

              <ContactForm
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={false}
                header={null}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={null}
                checkboxLabel=" By entering your information, you permit us to reach out to
                you with future communications."
                withContainer={true}
                businessType={"restaurant"}
                businessId={"2592"}
                locationId={3486}
                onSubmit={sendCareersFormEmail}
                fields={CAREER_FORM_FIELDS}
                destinationEmail="careers@eggnbird.com"
                additionalTexts={[
                  ` I certify that I have read and fully completed all of this application
                  and that the information contained in this application is correct to
                  the best of my knowledge. I understand that any omission or erroneous
                  information is grounds for dismissal in accordance with EGG N BIRD's
                  policy. I authorize the reference(s) listed in this application to
                  provide any and all information concerning my previous employment as
                  well as pertinent information they may have, personal or otherwise. I
                  release all parties from all liabilities for any damages that may
                  result from furnishing the aforementioned information. I acknowledge
                  that EGG N BIRD reserves the right to amend or modify the policies in
                  its Employee Handbook as well as other EGG N BIRD policies at any
                  time, without prior notice. These policies do not create any
                  promise(s) or contractual obligation(s) between EGG N BIRD and its
                  employee(s). At EGG N BIRD, my employment is at-will. This means I am
                  free to terminate my employment at any time or for any reason, with or
                  without cause. EGG N BIRD also retains these same rights.`,
                  `
                  EGG N BIRD is an Equal Opportunity Employer. Various federal, state,
                  and local laws prohibit discrimination on account of sex gender, race
                  or color, national origin, ancestry, sexual orientation, pregnancy,
                  citizenship status, age, religion, disability or medical condition,
                  marital status or military status. It is EGG N BIRD's policy to fully
                  comply with these laws, as applicable. The information requested in
                  this application will not be used for any purpose(s) prohibited by the
                  law.
                  `,
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
          hours {
            day
            open
            close
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
